html {
    height: 100%;
    margin: 0;
    display: flex;
}

body {
    flex: 1;
    display: flex;
}

#root {
    flex: 1;
    display: flex;
}

.tab-content {
    width: 100%;
    padding: 50px;
}